import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button, Icon } from '../lib';
import './notification.scss';
import { useDispatch } from 'react-redux';
import { clearState } from '../../redux/reducers/app';

interface NotificationProps {
  autoclose?: boolean;
  type: 'success' | 'error' | 'warning' | 'info';
  format?: string;
  icon?: string;
  text: string | null;
  autoClose?: boolean;
  time?: number;
}

export const Notification: React.FC<NotificationProps> = (props) => {
  let _class = 'notification';
  if (props.format) _class += ` ${props.format}`;
  const dispatch = useDispatch();
  if (!props.text) return null;

  const hideNotification = () => {
    dispatch(clearState());
  };

  if (props.autoClose) setTimeout(hideNotification, props.time ? props.time * 1000 : 2000);

  return (
    <CSSTransition in appear timeout={0} classNames={_class}>
      <div className={`notification ${props.type} ${props.format || ''} ${props.icon ? 'with-icon' : ''}`}>
        {props.icon && <Icon pack="feather" size={19} color="dark" image={props.icon} />}
        <p>{props.text}</p>
        {!props.autoclose && (
          <Button
            className="btn-close-notification"
            text="x"
            color={props.format === 'toast' ? 'dark' : 'light'}
            action={hideNotification}
          />
        )}
      </div>
    </CSSTransition>
  );
};
